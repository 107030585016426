// @flow
//Core
import React from 'react';
import classNames from 'classnames';
import { Grid, Box, Typography, Button, Link } from '@material-ui/core';
//Local
import typography from '../../../../styles/typography.module.scss';
import { ShareButtons } from '../../../../components';
import { jobStyles } from '../styles';

const ArticleSec = ({
  title,
  subtitle,
  date,
  author,
  tags,
  content,
  shareUrl,
  applyLink,
}: {
  title: string,
  subtitle?: string,
  date: string,
  author: string,
  tags: Array<Object>,
  content: Object,
  shareUrl: string,
  applyLink: string,
}) => {
  const styles = jobStyles();
  const tagsJSX = tags.map((tag, index) => (
    <Typography
      className={classNames(styles.tag, typography.textSmDark)}
      variant="subtitle1"
      component={Link}
      key={index}
      href={`/media_center/work_life/recruitment/#${tag}`}
    >
      {tag}
    </Typography>
  ));

  return (
    <>
      <section className={styles.articleSec}>
        <Typography
          component="h1"
          variant="h3"
          className={classNames(typography.withDividerLeftPurple, styles.title)}
        >
          {title}
        </Typography>
        <Typography className={styles.subtitle} variant="body1">
          {subtitle}
        </Typography>
        <Grid
          container
          justify="space-between"
          alignItems="flex-end"
          className={styles.info}
        >
          <Grid item className={styles.gridItemInfo}>
            <Typography className={classNames(styles.date)} variant="subtitle1">
              {date}
            </Typography>
            <Typography
              className={classNames(styles.author)}
              variant="subtitle1"
            >
              {author}
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              size="medium"
              className={styles.applyBtn}
              component="a"
              href={applyLink}
              target="_blank"
              rel="noreferrer noopener"
            >
              Apply
            </Button>
          </Grid>
          <Grid item>
            <ShareButtons url={shareUrl} />
          </Grid>
        </Grid>
        <Box
          className={classNames(styles.content)}
          dangerouslySetInnerHTML={{ __html: content }}
        />
        <Box className={styles.tags}>{tagsJSX}</Box>
        <Grid
          className={styles.footShare}
          container
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              size="medium"
              className={styles.applyBtn}
              component="a"
              href={applyLink}
              target="_blank"
              rel="noreferrer noopener"
            >
              Apply
            </Button>
          </Grid>
          <Grid item>
            <ShareButtons url={shareUrl} />
          </Grid>
        </Grid>
      </section>
    </>
  );
};

ArticleSec.defaultProps = {
  subtitle: '',
};

export default ArticleSec;
