//@flow
//Core
import React from 'react';
import get from 'lodash.get';
import { Container, Box } from '@material-ui/core';
import formatDate from 'date-format';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import imgNewsBanner from 'src/images/news-banner.png';
import { SITE_METADATA } from 'src/constants';

// //Local
import { Layout } from '../components';
import {
  BreadcrumbsSec,
  ArticleSec,
  RecommendSec,
} from '../components/mediaCenterSections/job';

import { jobStyles } from '../styles/pages/media_center.styles';
import { getOgData } from '../utils';

export const query = graphql`
  {
    allGreenhouseJobPost(limit: 4) {
      nodes {
        title
        location {
          name
        }
        content
        greenhouseId
        created_at
        parent {
          ... on GreenhouseDepartment {
            id
            name
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        ogImage
        twitterImage
        siteUrl
      }
    }
  }
`;

const Job = ({
  pageContext,
  data,
  location,
}: {
  pageContext: Object,
  data: Object,
  location: Object,
}) => {
  const styles = jobStyles();
  const breadcrumbs = [
    {
      title: 'トップ',
      url: '/',
    },
    {
      title: 'Career',
      url: '/recruit/',
    },
    {
      title: get(pageContext, 'title'),
    },
  ];
  const applyLink = `https://boards.greenhouse.io/paidyinc/jobs/${get(
    pageContext,
    'greenhouseId'
  )}#application`;
  const recommendedArticles = get(data, 'allGreenhouseJobPost.nodes', [])
    .filter((item) => item.greenhouseId !== pageContext.greenhouseId)
    .slice(0, 3);

  return (
    <Layout
      SEOProps={{
        title: SITE_METADATA.jobPost.title,
        description: SITE_METADATA.jobPost.description,
      }}
    >
      <Helmet
        meta={getOgData({
          title: SITE_METADATA.jobPost.title,
          siteMetadata: get(data, 'site.siteMetadata'),
        })}
      />
      <Box className={styles.jobPage}>
        <Box
          className={styles.jobPageBg}
          style={{
            backgroundImage: `url(${imgNewsBanner})`,
          }}
        />
        <Container maxWidth="md">
          <Box className={styles.paper}>
            <BreadcrumbsSec items={breadcrumbs} />
            <ArticleSec
              title={get(pageContext, 'title')}
              date={formatDate(
                'yyyy/MM/dd',
                new Date(get(pageContext, 'createdAt'))
              )}
              author={'Paidy'}
              tags={[get(pageContext, 'departmentName')]}
              content={get(pageContext, 'content')}
              shareUrl={location.href}
              applyLink={applyLink}
            />
            <RecommendSec recommendedArticles={recommendedArticles} />
          </Box>
        </Container>
      </Box>
    </Layout>
  );
};

export default Job;
