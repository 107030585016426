//Core
import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import get from 'lodash.get';
import formatDate from 'date-format';
//Local
import { Card } from '../../../../components';
import { recommendStyles } from '../styles';
import JobPreviewImg from '../../../../images/jobPreview.jpg';

const ReccomendSec = ({ recommendedArticles }) => {
  const styles = recommendStyles();
  const recommendedArticlesJSX = recommendedArticles.map((item, index) => (
    <Grid xs={12} md={4} key={index} item>
      <Card
        img={JobPreviewImg}
        title={item.title}
        tags={[get(item, 'parent.name')]}
        link={`/media_center/work_life/recruitment/job/${item.greenhouseId}`}
        variant="primary"
        theme="light"
        size="sm"
        date={formatDate('yyyy/MM/dd', new Date(get(item, 'created_at')))}
      />
    </Grid>
  ));

  return (
    <section className={styles.reccomendSec}>
      <Typography className={styles.title} variant="h3">
        こちらのポジションも募集中
      </Typography>
      <Grid container spacing={3}>
        {recommendedArticlesJSX}
      </Grid>
    </section>
  );
};

export default ReccomendSec;
